import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ShopService } from '../services/shop.service';
import { map } from 'rxjs';

export const shopGuard: CanActivateFn = (route, state) => {
  const matSnackBar = inject(MatSnackBar);
  const authService = inject(AuthService);
  const shopService = inject(ShopService);
  const router = inject(Router);
  let ok = false;
  let msg = 'Non puoi accedere a questa pagina';
  if (authService.isLoggedIn()) {
    if (route.data['id'] != null) ok = true;
    return shopService.getAllProduct().pipe(
      map((res) => {
        if (res.length > 0) {
          ok = true;
          return true;
        } else {
          msg = 'Work in progress';
          matSnackBar.open(msg, 'OK', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          return false;
        }
      })
    );
  } else {
    matSnackBar.open(msg, 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    router.navigate(['/']);
    return false;
  }
};
