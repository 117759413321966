import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { PublicModulesFunction } from '../../function/public-modules-function';

@Component({
  selector: 'app-cookie-dialog',
  standalone: true,
  imports: [
    ...PublicModulesFunction,
    RouterModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
  ],
  templateUrl: './cookie-dialog.component.html',
  styleUrl: './cookie-dialog.component.scss',
})
export class CookieDialogComponent {
  @Output() childEmitter: EventEmitter<any> = new EventEmitter<any>();

  checkImpCookie: boolean = false;

  panelOpenState1: boolean = false;
  panelOpenState2: boolean = false;
  panelOpenState2Check: boolean = false;
  panelOpenState3: boolean = false;
  panelOpenState3Check: boolean = false;

  commonService = inject(CommonService);

  confirmCookie() {
    this.commonService.setCookieAllValue();
    this.childEmitter.emit('false');

    let body = document.querySelector('body') as HTMLElement;
    body.classList.remove('noscroll');
  }

  togglePanel(data: any) {
    switch (data) {
      case '1':
        this.panelOpenState1 = !this.panelOpenState1;
        break;
      case '2':
        this.panelOpenState2 = !this.panelOpenState2;
        break;
      case '3':
        this.panelOpenState3 = !this.panelOpenState3;
        break;
    }
  }

  closeImp() {
    this.checkImpCookie = !this.checkImpCookie;

    let body = document.querySelector('body') as HTMLElement;
    body.classList.remove('noscroll');
  }

  notConfirm() {
    this.commonService.deleteCookieConsentValue();
    this.childEmitter.emit('not');

    let body = document.querySelector('body') as HTMLElement;
    body.classList.remove('noscroll');
  }

  openFormCookie() {
    this.checkImpCookie = true;

    let body = document.querySelector('body') as HTMLElement;
    body.classList.toggle('noscroll');
  }

  confirmSelected() {
    if (this.panelOpenState2Check) {
      if (this.panelOpenState3Check) {
        this.commonService.setCookieAllValue();
        this.childEmitter.emit('false');
      } else {
        this.commonService.setCookieExperienceValue();
        this.childEmitter.emit('false');
      }
    } else {
      if (this.panelOpenState3Check) {
        this.commonService.setCookieMisurazioniValue();
        this.childEmitter.emit('false');
      } else {
        this.commonService.setCookieNecessaryValue();
        this.childEmitter.emit('false');
      }
    }
  }
}
